import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Paper,
  Box,
  MenuItem,
} from "@mui/material";
import {
  Save as SaveIcon,
  UploadFile as UploadFileIcon,
} from "@mui/icons-material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import InputMask from "react-input-mask";
import api from "../../api";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Nome é obrigatório"),
  description: Yup.string(),
  cep: Yup.string().required("CEP é obrigatório"),
  state_id: Yup.number().required("Estado é obrigatório"),
  city_id: Yup.number().required("Cidade é obrigatória"),
  street_name: Yup.string().required("Nome da rua é obrigatório"),
  street_number: Yup.string().required("Número é obrigatório"),
  neighborhood: Yup.string().required("Bairro é obrigatório"),
  address_complement: Yup.string(),
  scheduled_start: Yup.string().required("Data de início é obrigatória"),
  scheduled_end: Yup.string().required("Data de fim é obrigatória"),
});

const EventCreate = () => {
  const navigate = useNavigate();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [initialValues] = useState({
    name: "",
    description: "",
    cep: "",
    state_id: "",
    city_id: "",
    street_name: "",
    street_number: "",
    neighborhood: "",
    address_complement: "",
    scheduled_start: "",
    scheduled_end: "",
    desktop_photo: null,
    mobile_photo: null,
  });

  // Carrega os estados ao montar o componente
  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await api.get("/admin/states");
        setStates(response.data);
      } catch (error) {
        console.error("Erro ao carregar estados:", error);
      }
    };
    fetchStates();
  }, []);

  // Função para buscar o endereço pelo CEP
  const fetchAddressByCep = async (cep, setFieldValue) => {
    try {
      const sanitizedCep = cep.replace(/\D/g, "");
      if (sanitizedCep.length === 8) {
        const response = await fetch(
          `https://viacep.com.br/ws/${sanitizedCep}/json/`
        );
        const data = await response.json();

        if (!data.erro) {
          // Encontrar o estado correspondente
          const selectedState = states.find((state) => state.acronym === data.uf);
          if (selectedState) {
            setFieldValue("state_id", selectedState.id);

            // Buscar cidades do estado
            const citiesResponse = await api.get(
              `/admin/states/${selectedState.id}/cities`
            );
            setCities(citiesResponse.data || []);

            // Encontrar a cidade correspondente
            const selectedCity = citiesResponse.data.find(
              (city) => city.name.toLowerCase() === data.localidade.toLowerCase()
            );
            if (selectedCity) {
              setFieldValue("city_id", selectedCity.id);
            } else {
              setFieldValue("city_id", "");
            }
          }

          setFieldValue("street_name", data.logradouro || "");
          setFieldValue("neighborhood", data.bairro || "");
          setFieldValue("address_complement", data.complemento || "");
        } else {
          alert("CEP não encontrado.");
        }
      }
    } catch (error) {
      console.error("Erro ao buscar endereço pelo CEP:", error);
      alert("Erro ao buscar endereço. Verifique o CEP e tente novamente.");
    }
  };

  // Função para lidar com o envio do formulário
  const handleFormSubmit = async (values, { setSubmitting }) => {
    if (new Date(values.scheduled_start) > new Date(values.scheduled_end)) {
      alert("A data de início não pode ser posterior à data de fim.");
      setSubmitting(false);
      return;
    }

    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      if (values[key] !== null && values[key] !== "") {
        formData.append(`event[${key}]`, values[key]);
      }
    });

    try {
      const response = await api.post("/admin/events", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.status === 201) {
        alert("Evento criado com sucesso!");
        navigate("/producer/events");
      } else {
        alert(`Erro ao criar evento: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Erro ao criar evento:", error.response || error.message);
      alert("Erro ao criar evento. Verifique os dados e tente novamente.");
    } finally {
      setSubmitting(false);
    }
  };

  // Antes de renderizar o formulário, verificar se os estados foram carregados
  if (!states.length) {
    return <p>Carregando...</p>;
  }

  return (
    <Box sx={{ maxWidth: 1200, margin: "0 auto", padding: 3 }}>
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Typography variant="h5" gutterBottom>
          Criar Novo Evento
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            setFieldValue,
            isSubmitting,
          }) => (
            <Form>
              <Grid container spacing={3}>
                {/* Nome */}
                <Grid item xs={12}>
                  <TextField
                    label="Nome"
                    name="name"
                    fullWidth
                    value={values.name}
                    onChange={handleChange}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    required
                  />
                </Grid>
                {/* Descrição */}
                <Grid item xs={12}>
                  <TextField
                    label="Descrição"
                    name="description"
                    fullWidth
                    multiline
                    rows={4}
                    value={values.description}
                    onChange={handleChange}
                  />
                </Grid>
                {/* Upload de Imagens */}
                <Grid item xs={12}>
                  <Typography variant="h6">Upload de Imagens</Typography>
                  <Button
                    variant="contained"
                    component="label"
                    startIcon={<UploadFileIcon />}
                    sx={{ mr: 2 }}
                  >
                    Upload Imagem Desktop
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={(e) => {
                        setFieldValue("desktop_photo", e.currentTarget.files[0]);
                      }}
                    />
                  </Button>
                  {values.desktop_photo && (
                    <Box sx={{ mt: 2 }}>
                      {typeof values.desktop_photo === "string" ? (
                        <img
                          src={values.desktop_photo}
                          alt="Imagem Desktop Existente"
                          style={{
                            maxWidth: "100%",
                            height: "auto",
                            borderRadius: 4,
                          }}
                        />
                      ) : (
                        <Typography variant="body2">
                          Arquivo: {values.desktop_photo.name}
                        </Typography>
                      )}
                    </Box>
                  )}
                  <Button
                    variant="contained"
                    component="label"
                    startIcon={<UploadFileIcon />}
                  >
                    Upload Imagem Mobile
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={(e) => {
                        setFieldValue("mobile_photo", e.currentTarget.files[0]);
                      }}
                    />
                  </Button>
                  {values.mobile_photo && (
                    <Box sx={{ mt: 2 }}>
                      {typeof values.mobile_photo === "string" ? (
                        <img
                          src={values.mobile_photo}
                          alt="Imagem Mobile Existente"
                          style={{
                            maxWidth: "100%",
                            height: "auto",
                            borderRadius: 4,
                          }}
                        />
                      ) : (
                        <Typography variant="body2">
                          Arquivo: {values.mobile_photo.name}
                        </Typography>
                      )}
                    </Box>
                  )}
                </Grid>
                {/* CEP */}
                <Grid item xs={12} sm={6}>
                  <InputMask
                    mask="99999-999"
                    value={values.cep}
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.value.replace(/\D/g, "").length === 8) {
                        fetchAddressByCep(e.target.value, setFieldValue);
                      }
                    }}
                  >
                    {(inputProps) => (
                      <TextField
                        {...inputProps}
                        label="CEP"
                        name="cep"
                        fullWidth
                        error={touched.cep && Boolean(errors.cep)}
                        helperText={touched.cep && errors.cep}
                      />
                    )}
                  </InputMask>
                </Grid>
                {/* Estado */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    label="Estado"
                    name="state_id"
                    fullWidth
                    value={values.state_id}
                    onChange={async (e) => {
                      handleChange(e);
                      setFieldValue("city_id", "");
                      const stateId = e.target.value;
                      if (stateId) {
                        const citiesResponse = await api.get(
                          `/admin/states/${stateId}/cities`
                        );
                        setCities(citiesResponse.data || []);
                      } else {
                        setCities([]);
                      }
                    }}
                    error={touched.state_id && Boolean(errors.state_id)}
                    helperText={touched.state_id && errors.state_id}
                  >
                    {states.map((state) => (
                      <MenuItem key={state.id} value={state.id}>
                        {state.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {/* Cidade */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    label="Cidade"
                    name="city_id"
                    fullWidth
                    value={
                      cities.some((city) => city.id === values.city_id)
                        ? values.city_id
                        : ""
                    }
                    onChange={handleChange}
                    error={touched.city_id && Boolean(errors.city_id)}
                    helperText={touched.city_id && errors.city_id}
                  >
                    {cities.map((city) => (
                      <MenuItem key={city.id} value={city.id}>
                        {city.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {/* Nome da Rua */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Nome da Rua"
                    name="street_name"
                    fullWidth
                    value={values.street_name}
                    onChange={handleChange}
                    error={touched.street_name && Boolean(errors.street_name)}
                    helperText={touched.street_name && errors.street_name}
                  />
                </Grid>
                {/* Número */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Número"
                    name="street_number"
                    fullWidth
                    value={values.street_number}
                    onChange={handleChange}
                    error={touched.street_number && Boolean(errors.street_number)}
                    helperText={touched.street_number && errors.street_number}
                  />
                </Grid>
                {/* Bairro */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Bairro"
                    name="neighborhood"
                    fullWidth
                    value={values.neighborhood}
                    onChange={handleChange}
                    error={touched.neighborhood && Boolean(errors.neighborhood)}
                    helperText={touched.neighborhood && errors.neighborhood}
                  />
                </Grid>
                {/* Complemento do Endereço */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Complemento do Endereço"
                    name="address_complement"
                    fullWidth
                    value={values.address_complement}
                    onChange={handleChange}
                  />
                </Grid>
                {/* Data de Início */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Data de Início"
                    type="datetime-local"
                    name="scheduled_start"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={values.scheduled_start}
                    onChange={handleChange}
                    error={
                      touched.scheduled_start && Boolean(errors.scheduled_start)
                    }
                    helperText={touched.scheduled_start && errors.scheduled_start}
                  />
                </Grid>
                {/* Data de Fim */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Data de Fim"
                    type="datetime-local"
                    name="scheduled_end"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={values.scheduled_end}
                    onChange={handleChange}
                    error={touched.scheduled_end && Boolean(errors.scheduled_end)}
                    helperText={touched.scheduled_end && errors.scheduled_end}
                  />
                </Grid>
              </Grid>

              {/* Botão Salvar */}
              <Grid item xs={12} sx={{ textAlign: "center", mt: 3 }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Salvando..." : "Salvar"}
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </Box>
  );
};

export default EventCreate;
