// src/contexts/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import api, { setAuthToken } from '../api';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const initialAuthState = () => {
    const token = localStorage.getItem('authToken');
    if (token) {
      setAuthToken(token);
      return true;
    }
    return false;
  };

  const [isAuthenticated, setIsAuthenticated] = useState(initialAuthState);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (isAuthenticated) {
      api
        .get('/users/me')
        .then((response) => setUser(response.data))
        .catch(() => {
          setIsAuthenticated(false);
          setAuthToken(null);
          localStorage.removeItem('authToken');
        });
    }
  }, [isAuthenticated]);

  const login = async (email, password) => {
    try {
      const response = await api.post('/auth/login', { email, password });
      const token = response.data.token;
      const userData = response.data.user;
      localStorage.setItem('authToken', token);
      setAuthToken(token);
      setIsAuthenticated(true);
      setUser(userData);
      return true;
    } catch (error) {
      console.error('Login failed', error);
      return false;
    }
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    setAuthToken(null);
    setIsAuthenticated(false);
    setUser(null);
  };

  const register = async (userData) => {
    try {
      const response = await api.post('/auth/register', { user: userData });
      const token = response.data.token;
      const userResponse = response.data.user;
      
      // Salvar token e usuário
      localStorage.setItem('authToken', token);
      setAuthToken(token);
      setIsAuthenticated(true);
      setUser(userResponse);

      return true;
    } catch (error) {
      console.error('Registration failed', error);
      return false;
    }
  };

  const forgotPassword = async (email) => {
    try {
      const response = await api.post('/auth/forgot_password', { email });
      return response.data;
    } catch (error) {
      console.error('Forgot Password failed', error);
      return { error: 'Falha ao solicitar recuperação de senha.' };
    }
  };

  const resetPassword = async (token, password, passwordConfirmation) => {
    try {
      const response = await api.post('/auth/reset_password', {
        token,
        password,
        password_confirmation: passwordConfirmation,
      });
      return response.data;
    } catch (error) {
      console.error('Reset Password failed', error);
      return { error: 'Falha ao redefinir senha.' };
    }
  };

  return (
    <AuthContext.Provider value={{ 
      isAuthenticated, 
      user, 
      login, 
      logout, 
      register, 
      forgotPassword, 
      resetPassword 
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;