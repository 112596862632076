import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';
import api from './../api';

const PassScanner = ({ scannerUserId }) => {
  const [loading, setLoading] = useState(false);
  const [scanResult, setScanResult] = useState(null);
  const scannerRef = useRef(null);

  const handleQRCodeScan = useCallback(
    async (passIdentifier) => {
      setLoading(true);
      try {
        const response = await api.post(`/passes/${passIdentifier}/scan`, {
          scanner_user_id: scannerUserId,
        });
        setScanResult(response.data);
      } catch (error) {
        setScanResult({
          message: 'Erro ao validar o passe.',
          errors: error.response?.data?.errors || ['Erro desconhecido.'],
        });
      } finally {
        setLoading(false);
      }
    },
    [scannerUserId]
  );

  useEffect(() => {
    const startQrCodeScanner = () => {
      if (!scannerRef.current) {
        const qrCodeScanner = new Html5QrcodeScanner(
          "qr-reader",
          { fps: 10, qrbox: 250 },
          false
        );

        qrCodeScanner.render(
          (decodedText) => {
            qrCodeScanner.clear();
            handleQRCodeScan(decodedText);
          },
          (errorMessage) => {
            console.warn(`Erro na leitura do QR Code: ${errorMessage}`);
          }
        );

        scannerRef.current = qrCodeScanner;
      }
    };

    startQrCodeScanner();

    return () => {
      if (scannerRef.current) {
        scannerRef.current.clear();
        scannerRef.current = null;
      }
    };
  }, [handleQRCodeScan]);

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      {loading ? (
        <p>Carregando...</p>
      ) : scanResult ? (
        <div>
          <h3>{scanResult.main_line}</h3>
          <p>{scanResult.secondary_line}</p>
          <h4>Nome: {scanResult.pass_name}</h4>
          <p>CPF: {scanResult.user_credentials[1]}</p>
          <p>Gerado em: {scanResult.pass_generated_at}</p>
          <h4>Histórico de Acessos:</h4>
          <ul>
            {scanResult.access_history.map((access, index) => (
              <li key={index}>
                {new Date(access.created_at).toLocaleString('pt-BR')} - {access.granted_by.email}
              </li>
            ))}
          </ul>
          <button onClick={() => setScanResult(null)}>Escanear Outro QR Code</button>
        </div>
      ) : (
        <>
          <p>Escaneie o QR Code</p>
          <div id="qr-reader" style={{ width: '100%' }}></div>
        </>
      )}
    </div>
  );
};

export default PassScanner;
