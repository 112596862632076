import React, { useEffect, useState, useContext } from 'react';
import { Box, Typography, Button, CircularProgress, Tooltip, Paper, Divider } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { OrderContext } from '../../contexts/OrderContext';
import { ContentCopy, CreditCard, CheckCircle, QrCode } from '@mui/icons-material';
import api from '../../api';

const InvoicePage = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const { order, fetchOrderById } = useContext(OrderContext);
  const [loading, setLoading] = useState(true);
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const [isPaid, setIsPaid] = useState(false);

  useEffect(() => {
    const generateInvoice = async () => {
      try {
        const response = await api.post(`/orders/${orderId}/generate_pix_invoice`);
        setInvoiceDetails(response.data);
        fetchOrderById(orderId);
      } catch (error) {
        console.error('Erro ao gerar fatura Pix:', error);
        alert('Erro ao gerar a fatura Pix. Tente novamente.');
      } finally {
        setLoading(false);
      }
    };

    generateInvoice();
  }, [orderId, fetchOrderById]);

  const handleCopy = () => {
    navigator.clipboard.writeText(invoiceDetails.invoice_pix_qrcode_text);
    alert('Código Pix copiado para a área de transferência!');
  };

  const handleCheckPayment = async () => {
    try {
      const response = await api.get(`/orders/${orderId}/check_payment_status`);
      if (response.data.status === 'paid') {
        alert('O pagamento foi confirmado!');
        setIsPaid(true);
      } else {
        alert(`Status do pagamento: ${response.data.status}`);
      }
    } catch (error) {
      console.error('Erro ao verificar o status do pagamento:', error);
      alert('Erro ao verificar o status do pagamento. Tente novamente.');
    }
  };

  const handleCreditCardPayment = () => {
    if (order && order.invoice_url) {
      window.open(order.invoice_url, '_blank');
    } else {
      alert('URL de pagamento não disponível.');
    }
  };

  const handleViewTicket = () => {
    navigate('/profile');
  };

  if (loading) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <CircularProgress />
        <Typography variant="body1" sx={{ mt: 2 }}>
          Gerando fatura Pix...
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3, maxWidth: 700, mx: 'auto', borderRadius: 2, boxShadow: 3 }}>
      <Typography variant="h4" align="center" gutterBottom color="primary">
        Finalize sua Compra
      </Typography>
      <Typography variant="body1" color="textSecondary" align="center" sx={{ mb: 3 }}>
        Pedido #{orderId} | Garanta seu ingresso agora mesmo!
      </Typography>

      <Paper elevation={3} sx={{ p: 3, mb: 3, borderRadius: 2 }}>
        <Typography variant="h5" align="center" gutterBottom>
          <QrCode sx={{ fontSize: 40, color: 'secondary.main', mb: 1 }} />
          Pague com Pix
        </Typography>

        {invoiceDetails ? (
          <Box display="flex" flexDirection="column" alignItems="center" sx={{ mb: 2 }}>
            <Tooltip title="Clique para copiar o código Pix">
              <Box
                component="img"
                src={invoiceDetails.invoice_pix_qrcode_url}
                alt="QR Code Pix"
                sx={{ width: 200, height: 200, cursor: 'pointer', borderRadius: 1, mb: 2 }}
                onClick={handleCopy}
              />
            </Tooltip>
            <Typography variant="body2" color="textSecondary" align="center" sx={{ wordBreak: 'break-word', mb: 2 }}>
              Use o <strong>Pix Copia e Cola</strong> abaixo ou escaneie o QR Code acima:
            </Typography>
            <Tooltip title="Copiar código Pix">
              <Button
                variant="outlined"
                color="primary"
                startIcon={<ContentCopy />}
                onClick={handleCopy}
                sx={{ textTransform: 'none', mb: 1 }}
              >
                Copiar Código Pix
              </Button>
            </Tooltip>
          </Box>
        ) : (
          <Typography variant="body1" color="error" align="center">
            Erro ao carregar os detalhes da fatura.
          </Typography>
        )}

        <Divider sx={{ my: 2 }} />
        <Typography variant="body2" align="center" color="textSecondary">
          O pagamento é rápido, seguro e simplificado. Use o QR Code ou o código Pix acima.
        </Typography>
      </Paper>

      {isPaid ? (
        <Button
          variant="contained"
          color="success"
          startIcon={<CheckCircle />}
          onClick={handleViewTicket}
          fullWidth
          sx={{ mt: 4, py: 2 }}
        >
          Ver Meu Ingresso
        </Button>
      ) : (
        <>
          <Box display="flex" justifyContent="center" gap={2} sx={{ mt: 3 }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<CheckCircle />}
              onClick={handleCheckPayment}
              sx={{ px: 4, py: 1 }}
            >
              Verificar e confirmar Pagamento
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              endIcon={<CreditCard />}
              onClick={handleCreditCardPayment}
              sx={{ px: 4, py: 1 }}
            >
              Pagar com
            </Button>
          </Box>

          <Paper
            elevation={1}
            sx={{ p: 2, mt: 3, borderRadius: 1, backgroundColor: 'info.light', textAlign: 'center' }}
          >
            <Typography variant="body2" color="info.dark">
              Após a confirmação do pagamento, seu ingresso será disponibilizado em sua conta.
            </Typography>
          </Paper>
        </>
      )}
    </Box>
  );
};

export default InvoicePage;
