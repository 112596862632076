import React from 'react';
import { Box, Typography } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Box sx={{ padding: { xs: 2, md: 4 }, maxWidth: 800, margin: '0 auto' }}>
      <Typography variant="h4" gutterBottom>
        Política de Privacidade
      </Typography>
      <Typography variant="body1" paragraph>
        Esta Política de Privacidade descreve como coletamos, usamos e protegemos suas informações pessoais.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Coleta de Informações
      </Typography>
      <Typography variant="body1" paragraph>
        Coletamos informações pessoais que você nos fornece, como nome, e-mail e dados de navegação.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Uso de Informações
      </Typography>
      <Typography variant="body1" paragraph>
        Usamos as informações coletadas para melhorar nossos serviços e personalizar sua experiência.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Proteção de Informações
      </Typography>
      <Typography variant="body1" paragraph>
        Implementamos medidas de segurança para proteger suas informações pessoais contra acesso não autorizado.
      </Typography>
    </Box>
  );
};

export default PrivacyPolicy;
