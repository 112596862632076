import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Configura o token de autenticação no cabeçalho
export const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common['Authorization'];
  }
};

// Verificação para garantir que a baseURL está configurada
if (!process.env.REACT_APP_API_URL) {
  console.error("A variável de ambiente REACT_APP_API_URL não está definida!");
}

// Configura interceptores para lidar com respostas de erro globalmente
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.error("Sessão expirada. Faça login novamente.");
      // Adicione lógica para logout ou redirecionar ao login
    }
    return Promise.reject(error);
  }
);

export default api;
