import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import api from "../../../api";

const UpdateFeePercentage = ({ eventId: propEventId }) => {
  const { id } = useParams(); // Captura o ID da URL, caso seja necessário
  const eventId = propEventId || id; // Prioriza o ID passado via props
  const [feePercentage, setFeePercentage] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    if (!eventId) {
      setMessage('Evento não encontrado.');
      setInitialLoading(false);
      return;
    }

    const fetchEventDetails = async () => {
      try {
        const response = await api.get(`/admin/events/${eventId}`);
        setFeePercentage(response.data.event.fee_percentage || '');
      } catch (error) {
        console.error('Erro ao carregar detalhes do evento:', error);
        setMessage('Erro ao carregar a taxa de serviço do evento.');
      } finally {
        setInitialLoading(false);
      }
    };

    fetchEventDetails();
  }, [eventId]);

  const handleUpdate = async () => {
    if (feePercentage === '') {
      setMessage('Insira uma taxa válida.');
      return;
    }

    setLoading(true);
    setMessage('');

    try {
      const response = await api.patch(`/admin/events/${eventId}/update_fee_percentage`, {
        fee_percentage: parseFloat(feePercentage),
      });
      setMessage(response.data.message || 'Taxa atualizada com sucesso.');
    } catch (error) {
      setMessage('Erro ao atualizar a taxa. Verifique os dados e tente novamente.');
      console.error('Erro ao atualizar fee_percentage:', error);
    } finally {
      setLoading(false);
    }
  };

  if (initialLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography>Carregando...</Typography>
      </Box>
    );
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <Paper elevation={3} sx={{ p: 4, maxWidth: 400, width: '100%' }}>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          Alterar Taxa de Serviço
        </Typography>
        {eventId ? (
          <>
            <Typography variant="h6" align="center" gutterBottom>
              Evento ID: {eventId}
            </Typography>
            <TextField
              label="Nova Taxa (%)"
              type="number"
              value={feePercentage}
              onChange={(e) => setFeePercentage(e.target.value)}
              fullWidth
              margin="normal"
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
              onClick={handleUpdate}
              disabled={loading}
            >
              {loading ? 'Atualizando...' : 'Atualizar Taxa'}
            </Button>
          </>
        ) : (
          <Typography variant="body1" color="error" align="center">
            Evento não encontrado.
          </Typography>
        )}
        {message && (
          <Typography variant="body1" color="textSecondary" align="center" sx={{ mt: 2 }}>
            {message}
          </Typography>
        )}
      </Paper>
    </Box>
  );
};

export default UpdateFeePercentage;
