// src/contexts/EventContext.js
import React, { createContext, useState, useEffect } from 'react';
import api from '../api';

export const EventContext = createContext();

const EventProvider = ({ children }) => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    api
      .get('/events')
      .then((response) => setEvents(response.data))
      .catch((error) => console.error('Error fetching events', error));
  }, []);

  return (
    <EventContext.Provider value={{ events }}>
      {children}
    </EventContext.Provider>
  );
};

export default EventProvider;
