import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  IconButton,
  CircularProgress,
  Button,
} from "@mui/material";
import { Delete as DeleteIcon, ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../../api";

const ProducerUsers = () => {
  const { producerId } = useParams(); // ID da produtora da URL
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await api.get(`/admin/producers/${producerId}/users`);
        setUsers(response.data);
      } catch (err) {
        console.error("Erro ao carregar usuários:", err);
        setError("Erro ao carregar usuários. Tente novamente mais tarde.");
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [producerId]);

  const handleRemoveUser = async (userId) => {
    if (!window.confirm("Tem certeza que deseja remover este usuário?")) return;

    try {
      await api.delete(`/admin/producers/${producerId}/users/${userId}`);
      setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
      alert("Usuário removido com sucesso!");
    } catch (err) {
      console.error("Erro ao remover usuário:", err);
      alert("Erro ao remover usuário. Tente novamente.");
    }
  };

  const handleBack = () => {
    navigate("/admin/producers");
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Box sx={{ maxWidth: 800, margin: "0 auto", padding: 3 }}>
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={handleBack}
          sx={{ marginBottom: 2 }}
        >
          Voltar
        </Button>
        <Typography variant="h5" gutterBottom>
          Usuários Vinculados à Produtora
        </Typography>
        <List>
          {users.map((user) => (
            <ListItem
              key={user.id}
              secondaryAction={
                <IconButton
                  edge="end"
                  color="error"
                  onClick={() => handleRemoveUser(user.id)}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemText
                primary={`${user.name} (${user.email})`}
                secondary={`Telefone: ${user.phone}`}
              />
            </ListItem>
          ))}
          {users.length === 0 && (
            <Typography color="textSecondary" variant="body2">
              Nenhum usuário vinculado.
            </Typography>
          )}
        </List>
      </Paper>
    </Box>
  );
};

export default ProducerUsers;
