import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Paper,
  Box,
  MenuItem,
} from '@mui/material';
import { Save as SaveIcon } from '@mui/icons-material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';
import api from '../../../api';
import { useNavigate } from 'react-router-dom';

const ProducerCreate = () => {
  const navigate = useNavigate();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const initialValues = {
    name: '',
    slug: '',
    cnpj: '',
    contact_phone_1: '',
    contact_email: '',
    cep: '',
    street_name: '',
    street_number: '',
    neighborhood: '',
    address_complement: '',
    about: '',
    city_id: '',
    state_id: '',
    instagram: '',
    tiktok: '',
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('O nome é obrigatório.'),
    slug: Yup.string().required('O slug é obrigatório.'),
    cnpj: Yup.string().required('O CNPJ é obrigatório.'),
    contact_phone_1: Yup.string().required('O telefone de contato é obrigatório.'),
    instagram: Yup.string(),
    tiktok: Yup.string(),
    contact_email: Yup.string()
      .email('Formato de e-mail inválido.')
      .required('O e-mail é obrigatório.'),
    cep: Yup.string().required('O CEP é obrigatório.'),
    street_name: Yup.string().required('O nome da rua é obrigatório.'),
    street_number: Yup.string().required('O número é obrigatório.'),
    neighborhood: Yup.string().required('O bairro é obrigatório.'),
    address_complement: Yup.string(),
    about: Yup.string().required("O campo 'Sobre' é obrigatório."),
    state_id: Yup.string().required('O estado é obrigatório.'),
    city_id: Yup.string().required('A cidade é obrigatória.'),
  });

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await api.get('/admin/states');
        setStates(response.data);
      } catch (error) {
        console.error('Erro ao carregar estados:', error);
      }
    };

    fetchStates();
  }, []);

  const fetchCities = async (stateId) => {
    try {
      const response = await api.get(`/admin/states/${stateId}/cities`);
      setCities(response.data);
      return response.data;
    } catch (error) {
      console.error('Erro ao carregar cidades:', error);
      return [];
    }
  };

  const handleFormSubmit = async (values) => {
    try {
      const dataToSubmit = { ...values, active: true }; // Define active como true
      await api.post('/admin/producers', { producer: dataToSubmit });
      alert('Parceiro criado com sucesso!');
      navigate('/admin/producers');
    } catch (error) {
      console.error('Erro ao criar parceiro:', error);
      alert('Erro ao criar parceiro. Verifique os dados.');
    }
  };

  return (
    <Box sx={{ maxWidth: 800, margin: '0 auto', padding: 3 }}>
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Typography variant="h5" gutterBottom>
          Criar Novo Parceiro
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ values, errors, touched, handleChange, setFieldValue }) => (
            <Form>
              <Grid container spacing={3}>
                {/* Nome */}
                <Grid item xs={12}>
                  <TextField
                    label="Nome"
                    name="name"
                    fullWidth
                    value={values.name}
                    onChange={handleChange}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>
                {/* Instagram */}
                <Grid item xs={12}>
                  <TextField
                    label="Instagram"
                    name="instagram"
                    fullWidth
                    value={values.instagram}
                    onChange={handleChange}
                  />
                </Grid>
                {/* TikTok */}
                <Grid item xs={12}>
                  <TextField
                    label="TikTok"
                    name="tiktok"
                    fullWidth
                    value={values.tiktok}
                    onChange={handleChange}
                  />  
                </Grid>
                {/* CNPJ */}
                <Grid item xs={12}>
                  <Field name="cnpj">
                    {() => (
                      <InputMask
                        mask="99.999.999/9999-99"
                        value={values.cnpj}
                        onChange={(e) => setFieldValue('cnpj', e.target.value)}
                      >
                        {(inputProps) => (
                          <TextField
                            {...inputProps}
                            label="CNPJ"
                            fullWidth
                            error={touched.cnpj && Boolean(errors.cnpj)}
                            helperText={touched.cnpj && errors.cnpj}
                          />
                        )}
                      </InputMask>
                    )}
                  </Field>
                </Grid>
                {/* Slug */}
                <Grid item xs={12}>
                  <TextField
                    label="Slug"
                    name="slug"
                    fullWidth
                    value={values.slug}
                    onChange={handleChange}
                    error={touched.slug && Boolean(errors.slug)}
                    helperText={touched.slug && errors.slug}
                  />
                </Grid>
                {/* Telefone de Contato */}
                <Grid item xs={12}>
                  <Field name="contact_phone_1">
                    {() => (
                      <InputMask
                        mask="(99) 99999-9999"
                        value={values.contact_phone_1}
                        onChange={(e) =>
                          setFieldValue('contact_phone_1', e.target.value)
                        }
                      >
                        {(inputProps) => (
                          <TextField
                            {...inputProps}
                            label="Telefone de Contato"
                            fullWidth
                            error={
                              touched.contact_phone_1 && Boolean(errors.contact_phone_1)
                            }
                            helperText={
                              touched.contact_phone_1 && errors.contact_phone_1
                            }
                          />
                        )}
                      </InputMask>
                    )}
                  </Field>
                </Grid>
                {/* E-mail de Contato */}
                <Grid item xs={12}>
                  <TextField
                    label="E-mail de Contato"
                    name="contact_email"
                    fullWidth
                    value={values.contact_email}
                    onChange={handleChange}
                    error={touched.contact_email && Boolean(errors.contact_email)}
                    helperText={touched.contact_email && errors.contact_email}
                  />
                </Grid>
                {/* Sobre */}
                <Grid item xs={12}>
                  <TextField
                    label="Sobre"
                    name="about"
                    multiline
                    rows={4}
                    fullWidth
                    value={values.about}
                    onChange={handleChange}
                    error={touched.about && Boolean(errors.about)}
                    helperText={touched.about && errors.about}
                  />
                </Grid>
                {/* CEP */}
                <Grid item xs={12}>
                  <Field name="cep">
                    {() => (
                      <InputMask
                        mask="99999-999"
                        value={values.cep}
                        onChange={(e) => setFieldValue('cep', e.target.value)}
                      >
                        {(inputProps) => (
                          <TextField
                            {...inputProps}
                            label="CEP"
                            fullWidth
                            error={touched.cep && Boolean(errors.cep)}
                            helperText={touched.cep && errors.cep}
                          />
                        )}
                      </InputMask>
                    )}
                  </Field>
                </Grid>
                {/* Rua */}
                <Grid item xs={12}>
                  <TextField
                    label="Rua"
                    name="street_name"
                    fullWidth
                    value={values.street_name}
                    onChange={handleChange}
                    error={touched.street_name && Boolean(errors.street_name)}
                    helperText={touched.street_name && errors.street_name}
                  />
                </Grid>
                {/* Número */}
                <Grid item xs={12}>
                  <TextField
                    label="Número"
                    name="street_number"
                    fullWidth
                    value={values.street_number}
                    onChange={handleChange}
                    error={touched.street_number && Boolean(errors.street_number)}
                    helperText={touched.street_number && errors.street_number}
                  />
                </Grid>
                {/* Complemento */}
                <Grid item xs={12}>
                  <TextField
                    label="Complemento"
                    name="address_complement"
                    fullWidth
                    value={values.address_complement}
                    onChange={handleChange}
                  />
                </Grid>
                {/* Bairro */}
                <Grid item xs={12}>
                  <TextField
                    label="Bairro"
                    name="neighborhood"
                    fullWidth
                    value={values.neighborhood}
                    onChange={handleChange}
                    error={touched.neighborhood && Boolean(errors.neighborhood)}
                    helperText={touched.neighborhood && errors.neighborhood}
                  />
                </Grid>
                {/* Estado */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    label="Estado"
                    name="state_id"
                    fullWidth
                    value={values.state_id}
                    onChange={async (e) => {
                      handleChange(e);
                      await fetchCities(e.target.value);
                      setFieldValue('city_id', ''); // Limpa a cidade ao trocar de estado
                    }}
                    error={touched.state_id && Boolean(errors.state_id)}
                    helperText={touched.state_id && errors.state_id}
                  >
                    {states.map((state) => (
                      <MenuItem key={state.id} value={state.id}>
                        {state.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {/* Cidade */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    label="Cidade"
                    name="city_id"
                    fullWidth
                    value={values.city_id}
                    onChange={handleChange}
                    error={touched.city_id && Boolean(errors.city_id)}
                    helperText={touched.city_id && errors.city_id}
                  >
                    {cities.map((city) => (
                      <MenuItem key={city.id} value={city.id}>
                        {city.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {/* Botão de Enviar */}
                <Grid item xs={12} sx={{ textAlign: 'center', marginTop: 2 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                  >
                    Salvar Produtor
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
    </Box>
  );
};

export default ProducerCreate;
