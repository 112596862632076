import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0c49c4',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#000000', // Cor secundária: preto
      contrastText: '#ffffff', // Texto branco nos elementos secundários
    },
    background: {
      default: '#ffffff', // Fundo padrão branco
      paper: '#F6F6F6', // Fundo dos componentes de papel (ex: Card) em cinza claro
      alternate: '#F2F2F2', // Cor de fundo alternativa
    },
    text: {
      primary: '#000000', // Texto principal em preto
      secondary: '#0c49c4', // Texto secundário em roxo
      title: '#00FFE0', // Cor para alguns títulos
    },
    action: {
      alternateButton: '#B7B7B7', // Cor para botões alternativos
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Fonte padrão
    h6: {
      color: '#00FFE0', // Cor personalizada para alguns títulos
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none', // Evita que os botões fiquem com texto em maiúsculas
          borderRadius: 8, // Bordas mais arredondadas
        },
        outlined: {
          backgroundColor: '#B7B7B7', // Botão alternativo com fundo cinza
          color: '#ffffff', // Texto em branco
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#000000', // Cor da barra de navegação em roxo
          color: '#ffffff', // Texto branco na AppBar
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#F6F6F6', // Cor de fundo de Card ou Paper
        },
      },
    },
  },
});

export default theme;