import React from 'react';
import { Routes, Route } from 'react-router-dom';

import HomePage from './pages/HomePage';
import LoginPage from './pages/auth/LoginPage';
import RegisterPage from './pages/auth/RegisterPage';
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage';
import ResetPasswordPage from './pages/auth/ResetPasswordPage';

import EventDetailsPage from './pages/events/EventDetailsPage';
import ProfilePage from './pages/ProfilePage';
import PassDetailsForm from './pages/orders/PassDetailsForm';
import InvoicePage from './pages/orders/InvoicePage';

import AdminDashboard from './pages/admin/AdminDashboard';

import AdminUserList from './pages/admin/user/AdminUserList';

import AdminEventList from './pages/admin/event/AdminEventList';
import AdminEventDetails from './pages/admin/event/AdminEventDetails';
import UpdateFeePercentage from './pages/admin/event/UpdateFeePercentage';

import ProducerList from './pages/admin/producer/ProducerList';
import ProducerCreate from './pages/admin/producer/ProducerCreate';
import ProducerEdit from './pages/admin/producer/ProducerEdit';
import AddUserToProducer from "./pages/admin/producer/AddUserToProducer";
import ProducerUsers from './pages/admin/producer/ProducerUsers';
import AdminOrdersList from './pages/admin/event/AdminOrdersList';
import OrdersList from './pages/producer/OrdersList';

import EventBatchesPage from './pages/producer/EventBatchesPage';

import ProducerDashboard from './pages/producer/ProducerDashboard';

import EventList from './pages/producer/EventList';
import EventCreate from './pages/producer/EventCreate';
import EventEdit from './pages/producer/EventEdit';
import CouponList from "./pages/producer/CouponList";
import NewCoupon from "./pages/producer/NewCoupon";
import EditCoupon from "./pages/producer/EditCoupon";
import PassList from "./pages/producer/PassList";

import PassDetailsPage from './pages/orders/PassDetailsPage';
import PassScanner from './components/PassScanner';

import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import RefundPolicy from './pages/RefundPolicy';
import FAQ from './pages/FAQ';

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<HomePage />} />
    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    <Route path="/terms-of-use" element={<TermsOfUse />} />
    <Route path="/refund-policy" element={<RefundPolicy />} />
    <Route path="/faq" element={<FAQ />} />

    <Route path="/login" element={<LoginPage />} />
    <Route path="/register" element={<RegisterPage />} />
    <Route path="/forgot-password" element={<ForgotPasswordPage />} />
    <Route path="/reset-password" element={<ResetPasswordPage />} />

    <Route path="/profile" element={<ProfilePage />} />
    <Route path="/passes/:passId" element={<PassDetailsPage />} />
    <Route path="/scanner/:scannerUserId" element={<PassScanner />} />

    <Route path="/events/:id" element={<EventDetailsPage />} />

    <Route path="/orders/:orderId/passes" element={<PassDetailsForm />} />
    <Route path="/orders/:orderId/invoice" element={<InvoicePage />} />

    <Route path="/admin" element={<AdminDashboard />} />

    <Route path="/admin/users" element={<AdminUserList />} />

    <Route path="/admin/events" element={<AdminEventList />} />
    <Route path="/admin/events/:id/details" element={<AdminEventDetails />} />
    <Route path="/admin/events/:id/update-fee-percentage" element={<UpdateFeePercentage />} />
    <Route path="/admin/events/:id/orders" element={<AdminOrdersList />} />

    <Route path="/admin/producers" element={<ProducerList />} />
    <Route path="/admin/producers/create" element={<ProducerCreate />} />
    <Route path="/admin/producers/:id/edit" element={<ProducerEdit />} />
    <Route path="/admin/producers/:id/add-user" element={<AddUserToProducer />} />
    <Route path="/admin/producers/:producerId/users" element={<ProducerUsers />} />

    <Route path="/producer" element={<ProducerDashboard />} />

    <Route path="/producer/events" element={<EventList />} />
    <Route path="/producer/events/create" element={<EventCreate />} />
    <Route path="/producer/events/:id/edit" element={<EventEdit />} />
    <Route path="/producer/events/:id/batches" element={<EventBatchesPage />} />

    <Route path="/producer/events/:id/orders" element={<OrdersList />} />

    <Route path="/producer/events/:id/coupons" element={<CouponList />} />
    <Route path="/producer/events/:id/coupons/new" element={<NewCoupon />} />
    <Route path="/producer/events/:id/coupons/:couponId/edit" element={<EditCoupon />} />

    <Route path="/producer/events/:id/passes" element={<PassList />} />

  </Routes>
);

export default AppRoutes;
