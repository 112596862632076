import React, { useState, useContext } from 'react';
import { AppBar, Toolbar, Button, IconButton, Box, useTheme, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import metaingressoslogo from '../assets/metaingressoslogo.png';

const Navbar = () => {
  const theme = useTheme();
  const { isAuthenticated, logout, user } = useContext(AuthContext);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const drawerContent = (
    <Box
      sx={{ width: 250 }}
      access="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button component={Link} to="/">
          <ListItemIcon sx={{ color: theme.palette.text.primary }}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" sx={{ color: theme.palette.text.primary }} />
        </ListItem>
        {isAuthenticated && (
          <>
            {user?.access === 'admin' && (
              <ListItem button component={Link} to="/admin">
                <ListItemIcon sx={{ color: theme.palette.text.primary }}>
                  <BusinessCenterIcon />
                </ListItemIcon>
                <ListItemText primary="Área do Administrador" sx={{ color: theme.palette.text.primary }} />
              </ListItem>
            )}
            {user?.access === 'producer_admin' && (
              <ListItem button component={Link} to="/producer">
                <ListItemIcon sx={{ color: theme.palette.text.primary }}>
                  <BusinessCenterIcon />
                </ListItemIcon>
                <ListItemText primary="Área do Produtor" sx={{ color: theme.palette.text.primary }} />
              </ListItem>
            )}
            <ListItem button component={Link} to="/profile">
              <ListItemIcon sx={{ color: theme.palette.text.primary }}>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary="Perfil" sx={{ color: theme.palette.text.primary }} />
            </ListItem>
            <ListItem button onClick={logout}>
              <ListItemIcon sx={{ color: theme.palette.text.primary }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" sx={{ color: theme.palette.text.primary }} />
            </ListItem>
          </>
        )}
        {!isAuthenticated && (
          <>
            <ListItem button component={Link} to="/login">
              <ListItemIcon sx={{ color: theme.palette.text.primary }}>
                <LoginIcon />
              </ListItemIcon>
              <ListItemText primary="Login" sx={{ color: theme.palette.text.primary }} />
            </ListItem>
            <ListItem button component={Link} to="/register">
              <ListItemIcon sx={{ color: theme.palette.text.primary }}>
                <LoginIcon />
              </ListItemIcon>
              <ListItemText primary="Cadastrar" sx={{ color: theme.palette.text.primary }} />
            </ListItem>
          </>
        )}
      </List>
    </Box>
  );

  return (
    <AppBar
      position="static"
      sx={{
        background: 'linear-gradient(90deg, #ECE5F2 0%, #FFFFFF 100%)',
        boxShadow: theme.shadows[4],
        color: theme.palette.text.primary,
      }}
    >
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer(true)}
          sx={{ display: { xs: 'block', md: 'none' }, mr: 2, color: theme.palette.text.primary }}
        >
          <MenuIcon />
        </IconButton>

        <Box
          component={Link}
          to="/"
          sx={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
          }}
        >
          <img
            src={metaingressoslogo}
            alt="MetaIngressos Logo"
            style={{
              height: '40px',
              objectFit: 'contain',
            }}
          />
        </Box>

        <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 2 }}>
          {isAuthenticated && (
            <>
              {user?.access === 'admin' && (
                <Button
                  color="inherit"
                  component={Link}
                  to="/admin"
                  startIcon={<BusinessCenterIcon />}
                  sx={{ color: theme.palette.text.primary }}
                >
                  Área do Administrador
                </Button>
              )}
              {user?.access === 'producer_admin' && (
                <Button
                  color="inherit"
                  component={Link}
                  to="/producer"
                  startIcon={<BusinessCenterIcon />}
                  sx={{ color: theme.palette.text.primary }}
                >
                  Área do Produtor
                </Button>
              )}
              <Button
                color="inherit"
                component={Link}
                to="/profile"
                startIcon={<AccountCircleIcon />}
                sx={{ color: theme.palette.text.primary }}
              >
                Perfil
              </Button>
              <Button
                color="inherit"
                onClick={logout}
                startIcon={<LogoutIcon />}
                sx={{ color: theme.palette.text.primary }}
              >
                Sair
              </Button>
            </>
          )}
          {!isAuthenticated && (
            <>
              <Button
                color="inherit"
                component={Link}
                to="/login"
                startIcon={<LoginIcon />}
                sx={{ color: theme.palette.text.primary }}
              >
                Login
              </Button>
              <Button
                color="inherit"
                component={Link}
                to="/register"
                startIcon={<LoginIcon />}
                sx={{ color: theme.palette.text.primary }}
              >
                Cadastrar
              </Button>
            </>
          )}
        </Box>

        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
          {drawerContent}
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
