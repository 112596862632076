import React, { useState } from 'react';
import { Avatar, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { MusicNote, TheaterComedy, School, Tour, Toys, Book, Church } from '@mui/icons-material';

const categories = [
  { icon: MusicNote, label: 'Shows', key: 'festas' },
  { icon: TheaterComedy, label: 'Teatro', key: 'teatro' },
  { icon: School, label: 'Cursos', key: 'cursos' },
  { icon: Tour, label: 'Passeios', key: 'passeios' },
  { icon: Toys, label: 'Infantis', key: 'infantis' },
  { icon: Book, label: 'Literatura', key: 'literatura' },
  { icon: Church, label: 'Espiritual', key: 'religioso' },
];

const CategoryFilter = ({ onFilter }) => {
  const theme = useTheme();
  const [selectedCategory, setSelectedCategory] = useState('');
  const scrollRef = React.useRef(null);

  const handleFilter = (key) => {
    if (selectedCategory === key) {
      setSelectedCategory('');
      onFilter('');
    } else {
      setSelectedCategory(key);
      onFilter(key);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        width: '100%',
        marginTop: 4,
      }}
    >
      <Box
        sx={{
          position: 'relative',
          paddingY: 2,
          maxWidth: '100%',
          overflow: 'hidden',
        }}
      >

        {/* Lista de Categorias */}
        <Box
          ref={scrollRef}
          sx={{
            display: 'flex',
            gap: 2,
            overflowX: 'auto',
            flexWrap: 'nowrap',
            scrollSnapType: 'x mandatory',
            paddingX: 2,
            '& > div': { scrollSnapAlign: 'center' },
            '&::-webkit-scrollbar': { display: 'none' },
          }}
        >
          {categories.map((category, index) => {
            const IconComponent = category.icon;
            const isSelected = selectedCategory === category.key;

            return (
              <Box
                key={index}
                onClick={() => handleFilter(category.key)}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  cursor: 'pointer',
                  textAlign: 'center',
                  minWidth: { xs: '60px', sm: '80px', md: '100px' },
                  position: 'relative',
                }}
              >
                <Avatar
                  sx={{
                    bgcolor: isSelected ? theme.palette.secondary.main : theme.palette.primary.main,
                    width: 60,
                    height: 60,
                    color: theme.palette.primary.contrastText,
                    transition: 'transform 0.3s, box-shadow 0.3s',
                    '&:hover': {
                      transform: 'scale(1.2)',
                      boxShadow: theme.shadows[4],
                    },
                  }}
                >
                  <IconComponent fontSize="medium" />
                </Avatar>
                <Typography
                  variant="caption"
                  sx={{
                    marginTop: 1,
                    color: isSelected ? theme.palette.secondary.main : theme.palette.primary.main,
                  }}
                >
                  {category.label}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default CategoryFilter;
