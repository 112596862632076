import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import api from '../api';

export const generatePDF = async (passId) => {
  try {
    // Buscar detalhes do passe
    const passResponse = await api.get(`/passes/${passId}`);
    const pass = passResponse.data;

    // Buscar detalhes do evento relacionado
    let relatedEvent = null;
    if (pass.event_id) {
      const eventResponse = await api.get(`/events/${pass.event_id}`);
      relatedEvent = eventResponse.data;
    }

    // Criar container temporário
    const container = document.createElement('div');
    container.style.position = 'absolute';
    container.style.top = '-9999px';
    container.style.left = '-9999px';
    container.style.background = '#f8f8f8';
    container.style.width = '400px'; // mesma largura usada no componente
    container.style.padding = '20px';
    container.style.fontFamily = 'Arial, sans-serif';
    container.style.color = '#000';
    document.body.appendChild(container);


    // Conteúdo HTML similar ao PassDetailsPage
    container.innerHTML = `
      <div style="background: #ffffff; border-radius: 8px; box-shadow: 0px 0px 5px rgba(0,0,0,0.1); overflow: hidden;">
        <div style="padding: 20px; text-align: center;">
          <h1 style="margin: 0; font-size: 24px; color: #1976d2; font-weight: bold; text-transform: uppercase;">Metaingressos</h1>
          <p style="margin: 0; font-size: 12px; color: #666;">www.metaingressos.com.br</p>
        </div>
        <hr style="margin: 10px 0;">
        <!-- QR Code -->
        <div style="text-align: center; padding: 10px;">
          <img src="${pass.qrcode_url || ''}" alt="QR Code" style="max-width: 100%; height: auto;"/>
        </div>
        <div style="padding: 20px;">
          <hr style="margin: 10px 0;">

          <!-- Nome do Evento -->
          <div style="display: flex; align-items: center; margin-bottom: 5px;">
            <img src="data:image/svg+xml;base64,${iconBase64('EventIcon')}" style="width:16px; height:16px; margin-right:5px;" alt="Evento"/>
            <h2 style="margin:0; font-size:18px; font-weight: bold;">
              ${relatedEvent ? relatedEvent.name : 'Evento não encontrado'}
            </h2>
          </div>

          <!-- Localização -->
          ${relatedEvent ? `
            <div style="display: flex; align-items: center; margin-bottom: 5px;">
              <img src="data:image/svg+xml;base64,${iconBase64('LocationOnIcon')}" style="width:16px; height:16px; margin-right:5px;" alt="Localização"/>
              <p style="margin:0; font-size:14px; color:#666;">
                ${relatedEvent.location || 'Localização não disponível'}
              </p>
            </div>
          ` : ''}

          <hr style="margin: 10px 0;">

          <!-- Informações Pessoais -->
          <div style="display: flex; align-items: center; margin-bottom: 5px;">
            <img src="data:image/svg+xml;base64,${iconBase64('PersonIcon')}" style="width:16px; height:16px; margin-right:5px;" alt="Nome"/>
            <p style="margin:0; font-size:14px; color:#666;">
              ${pass.name || 'Nome não disponível'}
            </p>
          </div>
          <div style="display: flex; align-items: center; margin-bottom: 5px;">
            <img src="data:image/svg+xml;base64,${iconBase64('BadgeIcon')}" style="width:16px; height:16px; margin-right:5px;" alt="CPF"/>
            <p style="margin:0; font-size:14px; color:#666;">
              CPF: ${pass.cpf || 'Não disponível'}
            </p>
          </div>
          <div style="display: flex; align-items: center; margin-bottom: 5px;">
            <img src="data:image/svg+xml;base64,${iconBase64('PhoneIcon')}" style="width:16px; height:16px; margin-right:5px;" alt="Telefone"/>
            <p style="margin:0; font-size:14px; color:#666;">
              ${pass.phone || 'Telefone não disponível'}
            </p>
          </div>
          <div style="display: flex; align-items: center; margin-bottom: 5px;">
            <img src="data:image/svg+xml;base64,${iconBase64('EmailIcon')}" style="width:16px; height:16px; margin-right:5px;" alt="Email"/>
            <p style="margin:0; font-size:14px; color:#666;">
              ${pass.email || 'Email não disponível'}
            </p>
          </div>

          <hr style="margin: 10px 0;">

          <p style="font-size:10px; text-align:center; color:#666; margin-top:10px;">
            Este passe é pessoal e intransferível. Para entrar no evento, apresente este ingresso e um documento de identificação com foto.
          </p>
        </div>
      </div>
    `;

    const canvas = await html2canvas(container, { scale: 2 });
    const imgData = canvas.toDataURL('image/png');

    const pdf = new jsPDF('p', 'mm', 'a4');
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();

    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;

    const scale = Math.min(pageWidth / canvasWidth, pageHeight / canvasHeight);
    const imgWidth = canvasWidth * scale;
    const imgHeight = canvasHeight * scale;

    pdf.addImage(imgData, 'PNG', (pageWidth - imgWidth) / 2, (pageHeight - imgHeight) / 2, imgWidth, imgHeight);
    pdf.save(`Ingresso_${passId}.pdf`);

    document.body.removeChild(container);
  } catch (error) {
    console.error('Erro ao gerar PDF:', error);
    alert('Erro ao gerar PDF do ingresso.');
  }
};

// Exemplo de função para converter ícones em base64 (opcional)
// Aqui você precisaria colocar o SVG do ícone codificado em base64 ou
// usar um dataURL. Para simplificar, é possível colocar um ícone genérico
// ou remover os ícones do PDF, apenas usando texto.
function iconBase64(iconName) {
  // Retorne aqui o base64 do seu ícone em SVG, ou coloque um SVG inline.
  // Ou simplesmente remova a parte do ícone se não for essencial.
  return '';
}