import React from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = () => {
  const questions = [
    {
      question: 'Como faço para criar uma conta?',
      answer: 'Clique em "Registrar", insira seus dados e siga as instruções na tela.',
    },
    {
      question: 'Como faço para recuperar minha senha?',
      answer: 'Na tela de login, clique em "Esqueci minha senha" e siga as instruções.',
    },
    {
      question: 'Quais métodos de pagamento são aceitos?',
      answer: 'Aceitamos cartões de crédito, débito e pagamentos via PIX.',
    },
    {
      question: 'Posso cancelar um pedido?',
      answer: 'Sim, pedidos podem ser cancelados antes do processamento. Entre em contato conosco para mais detalhes.',
    },
  ];

  return (
    <Box sx={{ padding: { xs: 2, md: 4 }, maxWidth: 800, margin: '0 auto' }}>
      <Typography variant="h4" gutterBottom>
        Perguntas Frequentes
      </Typography>
      {questions.map((item, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}-content`} id={`panel${index}-header`}>
            <Typography variant="h6">{item.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">{item.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default FAQ;
