import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  LinearProgress,
} from "@mui/material";
import api from "./../api";
import { useParams } from "react-router-dom";

const EventBatchesList = () => {
  const { id } = useParams(); // ID do evento vindo da rota
  const [batches, setBatches] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBatches = async () => {
      try {
        console.log("Fetching detailed batches...");
        const response = await api.get(`/admin/events/${id}/detailed_event_batches`);
        console.log("Detailed batches fetched:", response.data);
        setBatches(response.data);
      } catch (error) {
        console.error("Erro ao carregar batches:", error);
        alert("Erro ao carregar batches.");
      } finally {
        setLoading(false);
      }
    };

    fetchBatches();
  }, [id]);

  return (
    <Box sx={{ maxWidth: 1200, margin: "0 auto", padding: 3 }}>
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Typography variant="h6" gutterBottom>
          Lotes do Evento
        </Typography>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Tipo de Ingresso</strong></TableCell>
                  <TableCell><strong>Lote</strong></TableCell>
                  <TableCell><strong>Quantidade Total</strong></TableCell>
                  <TableCell><strong>Ingressos Vendidos</strong></TableCell>
                  <TableCell><strong>Ingressos Disponíveis</strong></TableCell>
                  <TableCell><strong>% Utilizado</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {batches.map((batch) => {
                  const percentageUsed = Math.round(
                    (batch.sold_quantity / batch.total_quantity) * 100
                  );
                  return (
                    <TableRow key={batch.id}>
                      <TableCell>{batch.pass_type}</TableCell>
                      <TableCell>{batch.name}</TableCell>
                      <TableCell>{batch.total_quantity}</TableCell>
                      <TableCell>{batch.sold_quantity}</TableCell>
                      <TableCell>{batch.available_quantity}</TableCell>
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          <Box sx={{ width: "100%", mr: 1 }}>
                            <LinearProgress
                              variant="determinate"
                              value={percentageUsed || 0}
                            />
                          </Box>
                          <Typography variant="body2" color="textSecondary">
                            {`${percentageUsed || 0}%`}
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {batches.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      Nenhum lote encontrado.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
    </Box>
  );
};

export default EventBatchesList;
