import React, { useState, useContext } from 'react';
import moment from 'moment';
import {
  Typography,
  Box,
  Paper,
  Divider,
  TextField,
  Button,
  IconButton,
} from '@mui/material';
import {
  ShoppingCart,
  AddCircle,
  RemoveCircle,
  CheckCircle,
  Error,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import api from './../api';
import { AuthContext } from '../contexts/AuthContext';
import AuthModal from './AuthModal';

export default function EventOrderItems(props) {
  const eventBatches = props.eventBatches || [];
  const navigate = useNavigate();

  const { isAuthenticated } = useContext(AuthContext); // Checa autenticação
  const [showAuthModal, setShowAuthModal] = useState(false); // Controle do modal de autenticação

  // Estado inicial dos lotes, incluindo os preços originais
  const initialBatchesState = eventBatches.map((eventBatch) => {
    const priceInCents = Number(eventBatch.price_in_cents) || 0;
    const feePercentage = Number(props.feePercentage) || 0;
    const feeInCents = priceInCents * (feePercentage / 100);
    const totalInCents = priceInCents + feeInCents;

    return {
      id: eventBatch.id,
      passType: eventBatch.pass_type,
      name: eventBatch.name,
      ends_at: eventBatch.ends_at,
      priceInCents: priceInCents,
      feeInCents: feeInCents,
      totalInCents: totalInCents,
      originalPriceInCents: priceInCents,
      originalFeeInCents: feeInCents,
      originalTotalInCents: totalInCents,
      quantity: 0,
      isSoldOut:
        eventBatch.quantity === 0 || new Date(eventBatch.ends_at) < new Date(),
    };
  });

  const [batchesInfosAndQuantities, setBatchesInfosAndQuantities] = useState(
    initialBatchesState
  );
  const [couponCode, setCouponCode] = useState('');
  const [couponResult, setCouponResult] = useState(null);

  const updateQuantity = (batchIndex, amount) => {
    const currentBatches = [...batchesInfosAndQuantities];
    const editedBatch = currentBatches[batchIndex];
    if (editedBatch.quantity === 0 && amount < 0) return;
    editedBatch.quantity += amount;
    setBatchesInfosAndQuantities(currentBatches);
  };

  const cartTotalInCents = () => {
    return batchesInfosAndQuantities.reduce((sum, batch) => {
      return sum + batch.quantity * batch.totalInCents;
    }, 0);
  };

  const applyCoupon = async () => {
    console.log("Iniciando a aplicação do cupom:", couponCode);
  
    if (!couponCode) {
      // Se o campo do cupom estiver vazio, resetamos os preços aos valores originais
      setBatchesInfosAndQuantities((prevBatches) =>
        prevBatches.map((batch) => ({
          ...batch,
          priceInCents: batch.originalPriceInCents,
          feeInCents: batch.originalFeeInCents,
          totalInCents: batch.originalTotalInCents,
        }))
      );
      setCouponResult(null);
      return;
    }
  
    try {
      const response = await api.get(
        `/coupons/${couponCode}?event_id=${props.event.id}`
      );
      console.log("Resposta da API para o cupom:", response.data);
  
      if (response.data.success) {
        const couponData = response.data.coupon;
  
        // Mapear os descontos por batch_id para fácil acesso
        const discountByBatchId = {};
        couponData.batches.forEach((couponBatch) => {
          discountByBatchId[couponBatch.event_batch_id] = {
            discount: Number(couponBatch.discount) || 0,
            kind: couponBatch.kind,
          };
        });
  
        console.log("Aplicando descontos aos lotes:", discountByBatchId);
  
        setBatchesInfosAndQuantities((prevBatches) =>
          prevBatches.map((batch) => {
            const coupon = discountByBatchId[batch.id];
            if (coupon) {
              const discountValue = coupon.discount;
              const feePercentage = Number(props.feePercentage) || 0;
              const originalPriceInCents = batch.originalPriceInCents;
  
              const newPriceInCents =
                coupon.kind === "percentage"
                  ? originalPriceInCents * (1 - discountValue / 100)
                  : originalPriceInCents - discountValue * 100; // Multiplica por 100 se o desconto for em reais
  
              const newPriceInCentsClamped = Math.max(newPriceInCents, 0);
  
              const newFeeInCents =
                newPriceInCentsClamped * (feePercentage / 100);
              const newTotalInCents = newPriceInCentsClamped + newFeeInCents;
  
              console.log(`Novo preço para o lote ${batch.id}:`, {
                priceInCents: newPriceInCentsClamped,
                feeInCents: newFeeInCents,
                totalInCents: newTotalInCents,
              });
  
              return {
                ...batch,
                priceInCents: newPriceInCentsClamped,
                feeInCents: newFeeInCents,
                totalInCents: newTotalInCents,
              };
            } else {
              // Lote não afetado pelo cupom, resetamos ao preço original
              return {
                ...batch,
                priceInCents: batch.originalPriceInCents,
                feeInCents: batch.originalFeeInCents,
                totalInCents: batch.originalTotalInCents,
              };
            }
          })
        );
  
        // Atualiza o resultado do cupom para exibir a mensagem apropriada
        setCouponResult({
          success: true,
          message: "Cupom aplicado aos lotes elegíveis.",
        });
      } else {
        console.log("Cupom não aplicável:", response.data.error);
        // Resetamos os preços aos valores originais
        setBatchesInfosAndQuantities((prevBatches) =>
          prevBatches.map((batch) => ({
            ...batch,
            priceInCents: batch.originalPriceInCents,
            feeInCents: batch.originalFeeInCents,
            totalInCents: batch.originalTotalInCents,
          }))
        );
        setCouponResult({ success: false, message: response.data.error });
      }
    } catch (error) {
      console.error("Erro ao aplicar o cupom:", error);
      // Resetamos os preços aos valores originais em caso de erro
      setBatchesInfosAndQuantities((prevBatches) =>
        prevBatches.map((batch) => ({
          ...batch,
          priceInCents: batch.originalPriceInCents,
          feeInCents: batch.originalFeeInCents,
          totalInCents: batch.originalTotalInCents,
        }))
      );
      setCouponResult({ success: false, message: "Erro ao buscar cupom" });
    }
  };  

  const handleCreateOrder = async () => {
    if (!isAuthenticated) {
      setShowAuthModal(true);
      return;
    }
  
    const selectedItems = batchesInfosAndQuantities
      .filter((batch) => batch.quantity > 0)
      .map((batch) => ({
        event_batch_id: batch.id,
        quantity: batch.quantity,
      }));
  
    const payload = {
      order: {
        event_id: props.event.id,
        event_batches: selectedItems,
        coupon_code: couponCode,
      },
    };
  
    try {
      const response = await api.post('/orders', payload);
  
      if (response.status === 201 && response.data.order?.id) {
        const createdOrderId = response.data.order.id; // Garante que o ID está acessível
        navigate(`/orders/${createdOrderId}/passes`);
      } else {
        console.error('Erro: Retorno inesperado da API', response.data);
      }
    } catch (error) {
      console.error('Erro ao criar a ordem:', error);
    }
  };  

  return (
    <>
      <AuthModal
        open={showAuthModal}
        onClose={() => setShowAuthModal(false)}
        onAuthSuccess={handleCreateOrder} // Refaz a criação da ordem após o login
      />
      <Paper elevation={3} sx={{ padding: 3, marginTop: 2 }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Typography variant="h5" color="primary">
            Ingressos
          </Typography>
          <Box display="flex" alignItems="center">
            <ShoppingCart
              fontSize="large"
              sx={{ color: 'primary.main', mr: 1 }}
            />
            <Typography variant="h6" color="primary">
              {(cartTotalInCents() / 100).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Typography>
            <Typography variant="p" color="textSecondary" sx={{ ml: 1 }}>
              + 10% de Taxa
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ marginY: 2 }} />

        <Box>
          {batchesInfosAndQuantities.map((batch, index) => (
            <Box
              key={batch.id}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={2}
            >
              <Box>
                <Typography variant="subtitle1" color="textPrimary">
                  {batch.passType} - {batch.name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {(batch.priceInCents / 100).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                  {props.feePercentage > 0 &&
                    ` (+ ${(batch.feeInCents / 100).toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })} taxa)`}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  Vendas até {moment(batch.ends_at).format('DD/MM/YYYY')}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                {batch.isSoldOut ? (
                  <Typography variant="body2" color="error">
                    Esgotado
                  </Typography>
                ) : (
                  <>
                    <IconButton
                      color="primary"
                      onClick={() => updateQuantity(index, -1)}
                    >
                      <RemoveCircle />
                    </IconButton>
                    <Typography
                      variant="body2"
                      sx={{ width: 30, textAlign: 'center' }}
                    >
                      {batch.quantity}
                    </Typography>
                    <IconButton
                      color="primary"
                      onClick={() => updateQuantity(index, 1)}
                    >
                      <AddCircle />
                    </IconButton>
                  </>
                )}
              </Box>
            </Box>
          ))}
        </Box>

        <Divider sx={{ marginY: 2 }} />

        <Box display="flex" alignItems="center" mb={3}>
          <TextField
            label="Cupom de desconto"
            variant="outlined"
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
            fullWidth
            sx={{ maxWidth: 300 }}
          />
          <Button
            variant="contained"
            color="success"
            onClick={applyCoupon}
            sx={{ marginLeft: 2 }}
          >
            Aplicar
          </Button>
        </Box>

        {couponResult && (
          <Box display="flex" alignItems="center" mb={2}>
            {couponResult.success ? (
              <>
                <CheckCircle color="primary" />
                <Typography variant="body2" color="primary" sx={{ ml: 1 }}>
                  {couponResult.message}
                </Typography>
              </>
            ) : (
              <>
                <Error color="error" />
                <Typography variant="body2" color="error" sx={{ ml: 1 }}>
                  {couponResult.message}
                </Typography>
              </>
            )}
          </Box>
        )}

        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 3 }}
          onClick={handleCreateOrder}
        >
          Comprar Ingressos
        </Button>
      </Paper>
    </>
  );
}
