import React from 'react';
import { Box, Typography } from '@mui/material';

const RefundPolicy = () => {
  return (
    <Box sx={{ padding: { xs: 2, md: 4 }, maxWidth: 800, margin: '0 auto' }}>
      <Typography variant="h4" gutterBottom>
        Política de Reembolso
      </Typography>
      <Typography variant="body1" paragraph>
        Nossa política de reembolso descreve as condições e procedimentos para solicitações de devolução.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Condições de Reembolso
      </Typography>
      <Typography variant="body1" paragraph>
        Oferecemos reembolsos em caso de problemas técnicos ou se o serviço não atender às suas expectativas.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Procedimento
      </Typography>
      <Typography variant="body1" paragraph>
        Para solicitar um reembolso, entre em contato conosco por e-mail fornecendo detalhes do problema.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Prazo
      </Typography>
      <Typography variant="body1" paragraph>
        Reembolsos são processados dentro de 7 dias úteis após a aprovação da solicitação.
      </Typography>
    </Box>
  );
};

export default RefundPolicy;
