import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  Snackbar,
  TextField,
} from "@mui/material";
import { Visibility } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import api from "../../api";
import CityDistributionChart from "../../components/CityDistributionChart";

const OrdersList = () => {
  const { id } = useParams(); // ID do evento vindo da rota
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await api.get(`/admin/events/${id}/orders`);
        setOrders(response.data);
        setFilteredOrders(response.data);
      } catch (error) {
        console.error("Erro ao carregar ordens:", error);
        alert("Erro ao carregar ordens.");
      }
    };

    fetchOrders();
  }, [id]);

  useEffect(() => {
    const lowerCaseSearch = searchTerm.toLowerCase();
    const filtered = orders.filter((order) => {
      const searchableFields = [
        String(order.id),
        order.user?.name?.toLowerCase(),
        order.user?.email?.toLowerCase(),
        String(order.price_in_cents / 100),
        order.invoice_status?.toLowerCase(),
        new Date(order.created_at).toLocaleDateString("pt-BR").toLowerCase(),
        order.invoice_paid_at
          ? new Date(order.invoice_paid_at).toLocaleDateString("pt-BR").toLowerCase()
          : null,
      ];

      return searchableFields.some((field) => field?.includes(lowerCaseSearch));
    });

    setFilteredOrders(filtered);
  }, [searchTerm, orders]);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  // Resumo do evento
  const totalOrders = orders.length;
  const paidOrders = orders.filter((order) => order.invoice_status === "paid").length;
  const unpaidOrders = totalOrders - paidOrders;
  const percentagePaid = totalOrders ? ((paidOrders / totalOrders) * 100).toFixed(2) : 0;
  const totalRevenue = orders
    .filter((order) => order.invoice_status === "paid")
    .reduce((sum, order) => sum + order.price_in_cents, 0) / 100;

  // Preparar dados para o gráfico
  const dataByDate = orders.reduce((acc, order) => {
    const createdAt = new Date(order.created_at).toLocaleDateString("pt-BR");
    const paidAt = order.invoice_paid_at
      ? new Date(order.invoice_paid_at).toLocaleDateString("pt-BR")
      : null;

    if (!acc[createdAt]) {
      acc[createdAt] = { date: createdAt, created: 0, paid: 0 };
    }
    acc[createdAt].created += 1;

    if (paidAt) {
      if (!acc[paidAt]) {
        acc[paidAt] = { date: paidAt, created: 0, paid: 0 };
      }
      acc[paidAt].paid += 1;
    }

    return acc;
  }, {});

  const chartData = Object.values(dataByDate).sort(
    (a, b) => new Date(a.date.split("/").reverse().join("-")) - new Date(b.date.split("/").reverse().join("-"))
  );

  return (
    <Box sx={{ maxWidth: 1200, margin: "0 auto", padding: 3 }}>
      <Paper elevation={3} sx={{ padding: 4, marginBottom: 3 }}>
        <Typography variant="h5" gutterBottom>
          Resumo do Evento
        </Typography>
        <Typography variant="body1">
          <strong>Total de Faturas:</strong> {totalOrders}
        </Typography>
        <Typography variant="body1">
          <strong>Faturas Pagas:</strong> {paidOrders}
        </Typography>
        <Typography variant="body1">
          <strong>Faturas Não Pagas:</strong> {unpaidOrders}
        </Typography>
        <Typography variant="body1">
          <strong>% Pago:</strong> {percentagePaid}%
        </Typography>
        <Typography variant="body1">
          <strong>Receita Total (Pagos):</strong> R${totalRevenue.toFixed(2)}
        </Typography>
      </Paper>

      <Box sx={{ marginBottom: 3 }}>
        <CityDistributionChart orders={orders} />
      </Box>

      <Paper elevation={3} sx={{ padding: 4, marginBottom: 3 }}>
        <Typography variant="h5" gutterBottom>
            Gráfico de Faturas por Dia
        </Typography>
        <ResponsiveContainer width="100%" height={400}>
            <BarChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <RechartsTooltip />
            <Legend />
            <Bar dataKey="created" fill="#8884d8" name="Criadas" />
            <Bar dataKey="paid" fill="#82ca9d" name="Pagas" />
            </BarChart>
        </ResponsiveContainer>
        </Paper>

      <Paper elevation={3} sx={{ padding: 4 }}>
        <Typography variant="h5" gutterBottom>
          Faturas do Evento
        </Typography>
        <TextField
          fullWidth
          label="Pesquisar ordens"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Pesquise por ID, nome, email, preço, status, ou datas"
          sx={{ marginBottom: 2 }}
        />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><strong>ID</strong></TableCell>
                <TableCell><strong>Nome do Usuário</strong></TableCell>
                <TableCell><strong>Email do Usuário</strong></TableCell>
                <TableCell><strong>Status da Fatura</strong></TableCell>
                <TableCell><strong>Preço</strong></TableCell>
                <TableCell><strong>Criado em</strong></TableCell>
                <TableCell><strong>Pago em</strong></TableCell>
                <TableCell align="center"><strong>Ações</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredOrders.map((order) => (
                <TableRow key={order.id}>
                  <TableCell>{order.id}</TableCell>
                  <TableCell>{order.user?.name}</TableCell>
                  <TableCell>{order.user?.email}</TableCell>
                  <TableCell>{order.invoice_status}</TableCell>
                  <TableCell>R${(order.price_in_cents / 100).toFixed(2)}</TableCell>
                  <TableCell>
                    {new Date(order.created_at).toLocaleDateString("pt-BR")}
                  </TableCell>
                  <TableCell>
                    {order.invoice_paid_at
                      ? new Date(order.invoice_paid_at).toLocaleDateString("pt-BR")
                      : "Não pago"}
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Visualizar Fatura">
                      <IconButton
                        color="primary"
                        onClick={() => window.open(order.invoice_url, "_blank")}
                      >
                        <Visibility />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
              {filteredOrders.length === 0 && (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    Nenhuma ordem encontrada.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="Funcionalidade ainda não disponível."
      />
    </Box>
  );
};

export default OrdersList;
