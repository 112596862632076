import React, { useState, useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { TextField, Button, Typography, Paper, Box, Alert, CircularProgress } from '@mui/material';
import { EmailOutlined as EmailIcon } from '@mui/icons-material';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { forgotPassword } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    setMessage('');
    const response = await forgotPassword(email);
    setLoading(false);

    if (response.message) {
      setMessage(response.message);
    } else if (response.error) {
      setError(response.error);
    }
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" bgcolor="background.default" px={2}>
      <Paper elevation={3} sx={{ padding: 4, maxWidth: 400, width: '100%' }}>
        {/* Ícone e Título */}
        <Box display="flex" justifyContent="center" mb={2}>
          <EmailIcon color="primary" fontSize="large" />
        </Box>
        <Typography variant="h5" align="center" color="text.primary" gutterBottom>
          Esqueci Minha Senha
        </Typography>
        <Typography variant="body2" align="center" color="text.secondary" gutterBottom>
          Insira seu e-mail para receber o link de redefinição de senha.
        </Typography>

        {/* Mensagens de erro ou sucesso */}
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        {message && <Alert severity="success" sx={{ mb: 2 }}>{message}</Alert>}

        {/* Formulário */}
        <form onSubmit={handleSubmit}>
          <TextField
            label="E-mail"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
            required
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading}
            sx={{ mt: 2 }}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Enviar Link de Recuperação'}
          </Button>
        </form>

        {/* Voltar */}
        <Box mt={2} textAlign="center">
          <Button
            variant="text"
            color="primary"
            onClick={() => window.history.back()}
            sx={{ textDecoration: 'underline' }}
          >
            Voltar
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default ForgotPasswordPage;
