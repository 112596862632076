// src/pages/auth/LoginPage.js
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { TextField, Button, Typography, Paper, Box, Alert, Link } from '@mui/material';
import { LockOutlined as LockIcon } from '@mui/icons-material';

const LoginPage = () => {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(false); // Reset error state
    const success = await login(email, password);
    if (success) {
      navigate('/profile');
    } else {
      setError(true);
    }
  };

  const handleForgotPassword = () => {
    navigate('/forgot-password');
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" bgcolor="background.alternate">
      <Paper elevation={3} sx={{ padding: 4, maxWidth: 400, width: '100%' }}>
        <Box display="flex" justifyContent="center" mb={2}>
          <LockIcon color="primary" fontSize="large" />
        </Box>
        <Typography variant="h5" align="center" color="text.primary" gutterBottom>
          Login
        </Typography>
        {error && <Alert severity="error" sx={{ mb: 2 }}>Login falhou. Cheque seus dados.</Alert>}
        <form onSubmit={handleSubmit}>
          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
          >
            Login
          </Button>
        </form>
        <Box mt={2} textAlign="center">
          <Link component="button" onClick={handleForgotPassword} sx={{ cursor: 'pointer' }}>
            Esqueci minha senha
          </Link>
        </Box>
      </Paper>
    </Box>
  );
};

export default LoginPage;
