import React from 'react';
import { Box, Typography } from '@mui/material';

const TermsOfUse = () => {
  return (
    <Box sx={{ padding: { xs: 2, md: 4 }, maxWidth: 800, margin: '0 auto' }}>
      <Typography variant="h4" gutterBottom>
        Termos de Uso
      </Typography>
      <Typography variant="body1" paragraph>
        Ao acessar e utilizar nossos serviços, você concorda com os seguintes Termos de Uso.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Uso Permitido
      </Typography>
      <Typography variant="body1" paragraph>
        Você se compromete a usar nossos serviços apenas para fins legais e em conformidade com estes Termos.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Propriedade Intelectual
      </Typography>
      <Typography variant="body1" paragraph>
        Todo o conteúdo deste site, incluindo textos e imagens, é protegido por direitos autorais.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Alterações nos Termos
      </Typography>
      <Typography variant="body1" paragraph>
        Reservamo-nos o direito de alterar estes Termos de Uso a qualquer momento, sem aviso prévio.
      </Typography>
    </Box>
  );
};

export default TermsOfUse;
