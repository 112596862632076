import React from "react";
import { Typography, CardMedia, Box, Paper } from "@mui/material";
import { Instagram, WhatsApp, SettingsCell } from "@mui/icons-material";

const EventProducer = ({ producer }) => {
  if (!producer) return null;

  return (
    <Paper
      elevation={1}
      style={{
        padding: "15px",
        backgroundColor: "#fff8e1", // Cor personalizada para o produtor
      }}
    >
      <Typography variant="h6" gutterBottom>
        Organizador
      </Typography>
      <Box display="flex" alignItems="center" gap={2}>
        {producer.logo_url && (
          <CardMedia
            component="img"
            image={producer.logo_url}
            alt={producer.name}
            style={{
              width: 80,
              height: 80,
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        )}
        <Typography variant="body1">
          <strong>{producer.name}</strong>
        </Typography>
      </Box>
      {producer.about && (
        <Typography variant="body2" color="textSecondary" paragraph>
          {producer.about}
        </Typography>
      )}
      <Box display="flex" gap={2} marginTop="10px">
        {/* Instagram Icon */}
        {producer.instagram && (
          <a
            href={`https://instagram.com/${producer.instagram}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Typography
              variant="body1"
              color="primary"
              style={{ display: "flex", alignItems: "center", gap: 8 }}
            >
              <Instagram />
              Instagram
            </Typography>
          </a>
        )}

        {/* TikTok Icon */}
        {producer.tiktok && (
          <a
            href={`https://tiktok.com/@${producer.tiktok}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Typography
              variant="body1"
              color="primary"
              style={{ display: "flex", alignItems: "center", gap: 8 }}
            >
              <SettingsCell />
              TikTok
            </Typography>
          </a>
        )}

        {/* WhatsApp Icon */}
        {producer.contact_phone_1 && (
          <a
            href={`https://wa.me/${producer.contact_phone_1.replace(/\D/g, "")}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Typography
              variant="body1"
              color="primary"
              style={{ display: "flex", alignItems: "center", gap: 8 }}
            >
              <WhatsApp />
              WhatsApp
            </Typography>
          </a>
        )}
      </Box>
    </Paper>
  );
};

export default EventProducer;
