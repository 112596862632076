import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  Grid,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import api from "../../../api";

const AdminUserList = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    document_number: "",
    cep: "",
    street: "",
    number: "",
    complement: "",
    district: "",
    city_id: "",
    state_id: "",
    phone: "",
    password: "",
  });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await api.get("/users"); // Endpoint para listar usuários
        setUsers(response.data);
      } catch (err) {
        console.error("Erro ao carregar usuários:", err);
        setError("Erro ao carregar usuários.");
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    // Filtrar usuários com base no termo de pesquisa
    if (searchTerm) {
      const filtered = users.filter((user) =>
        `${user.name} ${user.email}`
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers(users);
    }
  }, [searchTerm, users]);

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setFormData({
      name: user.name,
      email: user.email,
      document_number: user.document_number || "",
      cep: user.cep || "",
      street: user.street || "",
      number: user.number || "",
      complement: user.complement || "",
      district: user.district || "",
      city_id: user.city_id || "",
      state_id: user.state_id || "",
      phone: user.phone_number || "",
      password: "", // Senha começa vazia
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedUser) {
      alert("Selecione um usuário para editar.");
      return;
    }

    setLoading(true);
    try {
      const updatedData = { ...formData };
      // Remove o campo de senha se não estiver preenchido
      if (!updatedData.password) delete updatedData.password;

      await api.put(`/users/${selectedUser.id}`, updatedData); // Endpoint para atualizar usuário
      alert("Usuário atualizado com sucesso!");
      const updatedUsers = users.map((user) =>
        user.id === selectedUser.id ? { ...user, ...formData } : user
      );
      setUsers(updatedUsers);
      setSelectedUser(null); // Limpar seleção após edição
    } catch (err) {
      console.error("Erro ao atualizar usuário:", err);
      alert("Erro ao atualizar usuário. Tente novamente.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ maxWidth: 800, margin: "0 auto", padding: 3 }}>
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Typography variant="h5" gutterBottom>
          Editar Usuário
        </Typography>
        {error && (
          <Typography color="error" gutterBottom>
            {error}
          </Typography>
        )}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              label="Pesquisar Usuário"
              fullWidth
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Digite o nome ou e-mail"
            />
            <List>
              {filteredUsers.map((user) => (
                <ListItem
                  key={user.id}
                  button
                  selected={selectedUser?.id === user.id}
                  onClick={() => handleUserSelect(user)}
                >
                  <ListItemText primary={`${user.name} (${user.email})`} />
                </ListItem>
              ))}
              {filteredUsers.length === 0 && !loading && (
                <Typography color="textSecondary" variant="body2">
                  Nenhum usuário encontrado.
                </Typography>
              )}
            </List>
            {loading && (
              <Box textAlign="center" mt={2}>
                <CircularProgress />
              </Box>
            )}
          </Grid>
          {selectedUser && (
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Editar Informações de {selectedUser.name}
              </Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  label="Nome"
                  name="name"
                  fullWidth
                  value={formData.name}
                  onChange={handleInputChange}
                  margin="normal"
                />
                <TextField
                  label="Email"
                  name="email"
                  fullWidth
                  value={formData.email}
                  onChange={handleInputChange}
                  margin="normal"
                />
                <TextField
                  label="Número do Documento"
                  name="document_number"
                  fullWidth
                  value={formData.document_number}
                  onChange={handleInputChange}
                  margin="normal"
                />
                <TextField
                  label="CEP"
                  name="cep"
                  fullWidth
                  value={formData.cep}
                  onChange={handleInputChange}
                  margin="normal"
                />
                <TextField
                  label="Rua"
                  name="street"
                  fullWidth
                  value={formData.street}
                  onChange={handleInputChange}
                  margin="normal"
                />
                <TextField
                  label="Número"
                  name="number"
                  fullWidth
                  value={formData.number}
                  onChange={handleInputChange}
                  margin="normal"
                />
                <TextField
                  label="Complemento"
                  name="complement"
                  fullWidth
                  value={formData.complement}
                  onChange={handleInputChange}
                  margin="normal"
                />
                <TextField
                  label="Bairro"
                  name="district"
                  fullWidth
                  value={formData.district}
                  onChange={handleInputChange}
                  margin="normal"
                />
                <TextField
                  label="Cidade (ID)"
                  name="city_id"
                  fullWidth
                  value={formData.city_id}
                  onChange={handleInputChange}
                  margin="normal"
                />
                <TextField
                  label="Estado (ID)"
                  name="state_id"
                  fullWidth
                  value={formData.state_id}
                  onChange={handleInputChange}
                  margin="normal"
                />
                <TextField
                  label="Telefone"
                  name="phone"
                  fullWidth
                  value={formData.phone}
                  onChange={handleInputChange}
                  margin="normal"
                />
                <TextField
                  label="Senha (Deixe vazio para não alterar)"
                  name="password"
                  type="password"
                  fullWidth
                  value={formData.password}
                  onChange={handleInputChange}
                  margin="normal"
                />
                <Box textAlign="center" mt={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                  >
                    {loading ? "Salvando..." : "Salvar Alterações"}
                  </Button>
                </Box>
              </form>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Box>
  );
};

export default AdminUserList;
