import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  Button,
  Paper,
} from "@mui/material";
import { AddCircle, Edit, LocalOffer, Group, QrCodeScanner } from "@mui/icons-material";
import api from "../../api";
import { AuthContext } from "../../contexts/AuthContext";

const EventList = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const { user } = useContext(AuthContext); // Move o useContext para o escopo principal

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await api.get(`/admin/events/by_producer`);
        setEvents(response.data);
      } catch (error) {
        console.error("Erro ao carregar eventos do produtor:", error);
        alert("Erro ao carregar eventos.");
      }
    };    
  
    if (user?.id) {
      fetchEvents();
    }
  }, [user]);  

  const handleCreateEvent = () => {
    navigate("/producer/events/create");
  };

  const handleEditEvent = (eventId) => {
    navigate(`/producer/events/${eventId}/edit`);
  };

  const handleEditBatches = (eventId) => {
    navigate(`/producer/events/${eventId}/batches`);
  };  

  const handleManageCoupons = (eventId) => {
    navigate(`/producer/events/${eventId}/coupons`);
  };

  const handleOrdersList = (eventId) => {
    navigate(`/producer/events/${eventId}/orders`);
  };

  const handleOpenScanner = () => {
    if (user?.id) {
      navigate(`/scanner/${user.id}`);
    } else {
      alert("Usuário não encontrado. Verifique sua autenticação.");
    }
  };

  const handleManageParticipants = (eventId) => {
    navigate(`/producer/events/${eventId}/passes`);
  };

  return (
    <Box sx={{ maxWidth: 1200, margin: "0 auto", padding: 3 }}>
      {/* Card para criar novo evento */}
      <Box mb={4}>
        <Card
          variant="outlined"
          sx={{
            textAlign: "center",
            padding: 2,
            cursor: "pointer",
            backgroundColor: "primary.main",
            color: "white",
          }}
          onClick={handleCreateEvent}
        >
          <CardContent>
            <IconButton size="large" sx={{ color: "white", marginBottom: 1 }}>
              <AddCircle fontSize="large" />
            </IconButton>
            <Typography variant="h6">Criar Novo Evento</Typography>
          </CardContent>
        </Card>
      </Box>

      {/* Listagem de eventos */}
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Typography variant="h5" gutterBottom>
          Lista de Eventos
        </Typography>
        <Grid container spacing={3}>
          {events.map((event) => (
            <Grid item xs={12} sm={6} md={4} key={event.id}>
              <Card variant="outlined" sx={{ padding: 2 }}>
                <CardContent>
                  <Typography variant="h6">{event.name}</Typography>
                  <Box mt={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<Edit />}
                      sx={{ marginBottom: 1, width: "100%" }}
                      onClick={() => handleEditEvent(event.id)}
                    >
                      Editar Evento
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      startIcon={<LocalOffer />}
                      sx={{ marginBottom: 1, width: "100%" }}
                      onClick={() => handleEditBatches(event.id)}
                    >
                      Criar/Editar Lotes
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      startIcon={<LocalOffer />}
                      sx={{ marginBottom: 1, width: "100%" }}
                      onClick={() => handleManageCoupons(event.id)}
                    >
                      Gerenciar Cupons
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      startIcon={<Group />}
                      sx={{ marginBottom: 1, width: "100%" }}
                      onClick={() => handleManageParticipants(event.id)}
                    >
                      Gerenciar Participantes
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      startIcon={<Group />}
                      sx={{ marginBottom: 1, width: "100%" }}
                      onClick={() => handleOrdersList(event.id)}
                    >
                      Ver pagamentos
                    </Button>
                    <Button
                      variant="outlined"
                      color="success"
                      startIcon={<QrCodeScanner />}
                      sx={{ width: "100%" }}
                      onClick={handleOpenScanner}
                    >
                      Validar Ingressos
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Box>
  );
};

export default EventList;
