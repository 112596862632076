import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Modal,
  Button,
  TextField,
  Typography,
  Paper,
  Divider,
  Grid,
  MenuItem,
  IconButton,
  InputAdornment,
} from "@mui/material";
import {
  Person,
  Lock,
  Email,
  LocationOn,
  Phone,
  Badge,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { AuthContext } from "../contexts/AuthContext";
import InputMask from "react-input-mask";
import api from "../api";

const AuthModal = ({ open, onClose, onAuthSuccess }) => {
  const { login, register } = useContext(AuthContext);
  const [isLogin, setIsLogin] = useState(true);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    name: "",
    password_confirmation: "",
    document_number: "",
    cep: "",
    phone_number: "",
    street: "",
    number: "",
    complement: "",
    district: "",
    city_id: "",
    state_id: "",
  });
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await api.get("/states");
        setStates(response.data);
      } catch (error) {
        console.error("Erro ao carregar estados:", error);
      }
    };
    fetchStates();
  }, []);

  const fetchCities = async (stateId) => {
    try {
      const response = await api.get(`/states/${stateId}/cities`);
      setCities(response.data);
    } catch (error) {
      console.error("Erro ao carregar cidades:", error);
    }
  };

  const handleStateChange = async (e) => {
    const selectedStateId = e.target.value;
    setFormData({ ...formData, state_id: selectedStateId, city_id: "" });
    await fetchCities(selectedStateId);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const togglePasswordVisibility = () => setShowPassword((prev) => !prev);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let success;
    if (isLogin) {
      success = await login(formData.email, formData.password);
    } else {
      success = await register(formData);
    }

    if (success) {
      onAuthSuccess();
      onClose();
    } else {
      alert(isLogin ? "Falha no login" : "Falha no cadastro");
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
          overflow: "auto",
          padding: 2,
        }}
      >
        <Paper
          elevation={3}
          sx={{
            maxWidth: 500,
            width: "100%",
            padding: 3,
            borderRadius: 2,
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          <Typography variant="h5" align="center" gutterBottom>
            {isLogin ? "Faça Login" : "Crie Sua Conta"}
          </Typography>
          <Divider sx={{ mb: 3 }} />
          <form onSubmit={handleSubmit}>
            {!isLogin && (
              <>
                <TextField
                  label="Nome"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Person />
                      </InputAdornment>
                    ),
                  }}
                />

                <InputMask
                  mask="999.999.999-99"
                  value={formData.document_number}
                  onChange={handleChange}
                >
                  {(inputProps) => (
                    <TextField
                      {...inputProps}
                      label="CPF"
                      name="document_number"
                      fullWidth
                      margin="normal"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Badge />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </InputMask>

                <InputMask
                  mask="(99) 99999-9999"
                  value={formData.phone_number}
                  onChange={handleChange}
                >
                  {(inputProps) => (
                    <TextField
                      {...inputProps}
                      label="Telefone"
                      name="phone_number"
                      fullWidth
                      margin="normal"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Phone />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </InputMask>

                <InputMask
                  mask="99999-999"
                  value={formData.cep}
                  onChange={handleChange}
                >
                  {(inputProps) => (
                    <TextField
                      {...inputProps}
                      label="CEP"
                      name="cep"
                      fullWidth
                      margin="normal"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocationOn />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </InputMask>

                <TextField
                  label="Rua"
                  name="street"
                  value={formData.street}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOn />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="Número"
                  name="number"
                  value={formData.number}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOn />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="Complemento"
                  name="complement"
                  value={formData.complement}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOn />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="Bairro"
                  name="district"
                  value={formData.district}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOn />
                      </InputAdornment>
                    ),
                  }}
                />

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      select
                      label="Estado"
                      name="state_id"
                      fullWidth
                      value={formData.state_id}
                      onChange={handleStateChange}
                      margin="normal"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocationOn />
                          </InputAdornment>
                        ),
                      }}
                    >
                      {states.map((state) => (
                        <MenuItem key={state.id} value={state.id}>
                          {state.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      select
                      label="Cidade"
                      name="city_id"
                      fullWidth
                      value={formData.city_id}
                      onChange={handleChange}
                      disabled={!formData.state_id}
                      margin="normal"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocationOn />
                          </InputAdornment>
                        ),
                      }}
                    >
                      {cities.map((city) => (
                        <MenuItem key={city.id} value={city.id}>
                          {city.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </>
            )}

            <TextField
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Email />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              label="Senha"
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleChange}
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {!isLogin && (
              <TextField
                label="Confirme a Senha"
                type={showPassword ? "text" : "password"}
                name="password_confirmation"
                value={formData.password_confirmation}
                onChange={handleChange}
                fullWidth
                margin="normal"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock />
                    </InputAdornment>
                  ),
                }}
              />
            )}

            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
            >
              {isLogin ? "Entrar" : "Cadastrar"}
            </Button>
            <Button
              onClick={() => setIsLogin(!isLogin)}
              fullWidth
              color="secondary"
              sx={{ mt: 2 }}
            >
              {isLogin
                ? "Não tem uma conta? Cadastre-se"
                : "Já tem uma conta? Faça login"}
            </Button>
          </form>
        </Paper>
      </Box>
    </Modal>
  );
};

export default AuthModal;
