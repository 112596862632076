import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Paper,
  LinearProgress,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../api";

const CouponList = () => {
  const { id } = useParams(); // ID do evento vindo da rota
  const navigate = useNavigate(); // Navegação entre páginas
  const [coupons, setCoupons] = useState([]);

  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const response = await api.get(`/admin/events/${id}/coupons`);
        setCoupons(response.data);
      } catch (error) {
        console.error("Erro ao carregar cupons:", error);
        alert("Erro ao carregar cupons.");
      }
    };

    fetchCoupons();
  }, [id]);

  const handleCreateCoupon = () => {
    navigate(`/producer/events/${id}/coupons/new`);
  };

  const handleEditCoupon = (couponId) => {
    navigate(`/producer/events/${id}/coupons/${couponId}/edit`);
  };

  return (
    <Box sx={{ maxWidth: 1200, margin: "0 auto", padding: 3 }}>
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Typography variant="h5" gutterBottom>
          Cupons do Evento
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 3 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateCoupon}
          >
            Criar Novo Cupom
          </Button>
        </Box>

        <Grid container spacing={3}>
          {coupons.map((coupon) => {
            const used = coupon.redemptions || 0; // Cupons utilizados
            const total = coupon.redemption_limit || 0; // Limite de cupons
            const available = total - used; // Cupons disponíveis
            const percentageUsed = total > 0 ? (used / total) * 100 : 0; // Porcentagem utilizada

            return (
              <Grid item xs={12} sm={6} md={4} key={coupon.id}>
                <Card variant="outlined">
                  <CardContent>
                    {/* Título do cupom */}
                    <Typography variant="h6">
                      {coupon.code || "Código não disponível"}
                    </Typography>

                    {/* Detalhes do cupom */}
                    <Typography variant="body2" color="textSecondary">
                      Desconto:{" "}
                      {coupon.discount
                        ? `${coupon.discount}${coupon.kind === "percentage" ? "%" : "R$"}`
                        : "Sem desconto"}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Válido até: {coupon.valid_until || "Indeterminado"}
                    </Typography>

                    {/* Estatísticas do cupom */}
                    <Box mt={2}>
                      <Typography variant="body2">
                        <strong>Utilizados:</strong> {used}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Disponíveis:</strong> {available}
                      </Typography>
                      <Typography variant="body2">
                        <strong>% Utilizados:</strong> {percentageUsed.toFixed(2)}%
                      </Typography>

                      {/* Barra de Progresso */}
                      <Box mt={1}>
                        <LinearProgress
                          variant="determinate"
                          value={percentageUsed}
                          sx={{ height: 10, borderRadius: 5 }}
                        />
                      </Box>
                    </Box>

                    {/* Botão de Gerenciamento */}
                    <Box mt={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => handleEditCoupon(coupon.id)}
                      >
                        Gerenciar
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Paper>
    </Box>
  );
};

export default CouponList;
