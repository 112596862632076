import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  Button,
  Paper,
} from "@mui/material";
import {
  Edit as EditIcon,
  PersonAdd as PersonAddIcon,
  AddCircle,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import api from "../../../api";

const ProducerList = () => {
  const [producers, setProducers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Função para buscar os produtores na API
  useEffect(() => {
    const fetchProducers = async () => {
      try {
        const response = await api.get("/admin/producers");
        setProducers(response.data);
        setLoading(false);
      } catch (err) {
        console.error("Erro ao carregar produtores:", err);
        setError("Erro ao carregar produtores. Tente novamente mais tarde.");
        setLoading(false);
      }
    };

    fetchProducers();
  }, []);

  const handleCreateProducer = () => {
    navigate("/admin/producers/create");
  };

  const handleEditProducer = (producerId) => {
    navigate(`/admin/producers/${producerId}/edit`);
  };

  const handleAddUserToProducer = (producerId) => {
    navigate(`/admin/producers/${producerId}/add-user`);
  };

  const handleViewUsers = (producerId) => {
    navigate(`/admin/producers/${producerId}/users`);
  };

  if (loading) return <Typography>Carregando...</Typography>;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Box sx={{ maxWidth: 1200, margin: "0 auto", padding: 3 }}>
      {/* Card para criar novo produtor */}
      <Box mb={4}>
        <Card
          variant="outlined"
          sx={{
            textAlign: "center",
            padding: 2,
            cursor: "pointer",
            backgroundColor: "primary.main",
            color: "white",
          }}
          onClick={handleCreateProducer}
        >
          <CardContent>
            <IconButton size="large" sx={{ color: "white", marginBottom: 1 }}>
              <AddCircle fontSize="large" />
            </IconButton>
            <Typography variant="h6">Adicionar Nova Produtora</Typography>
          </CardContent>
        </Card>
      </Box>

      {/* Listagem de produtores */}
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Typography variant="h5" gutterBottom>
          Lista de Produtoras
        </Typography>
        <Grid container spacing={3}>
          {producers.map((producer) => (
            <Grid item xs={12} sm={6} md={4} key={producer.id}>
              <Card variant="outlined" sx={{ padding: 2 }}>
                <CardContent>
                  <Typography variant="h6">{producer.name}</Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                    Contato: {producer.contact_email} | {producer.contact_phone_1}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                    CNPJ: {producer.cnpj}
                  </Typography>
                  <Typography
                    variant="body2"
                    color={producer.active ? "primary" : "error"}
                    sx={{ mt: 1 }}
                  >
                    Status: {producer.active ? "Ativo" : "Inativo"}
                  </Typography>

                  <Box mt={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<EditIcon />}
                      sx={{ marginBottom: 1, width: "100%" }}
                      onClick={() => handleEditProducer(producer.id)}
                    >
                      Editar Produtora
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      startIcon={<PersonAddIcon />}
                      sx={{ width: "100%" }}
                      onClick={() => handleAddUserToProducer(producer.id)}
                    >
                      Vincular Usuário
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<PersonAddIcon />}
                      sx={{ width: "100%", marginTop: 1 }}
                      onClick={() => handleViewUsers(producer.id)}
                    >
                      Ver Usuários Vinculados
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Box>
  );
};

export default ProducerList;
