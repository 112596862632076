import React from 'react';
import { Paper, Typography, Button, Grid } from '@mui/material';
import { Receipt } from '@mui/icons-material';

const PendingOrders = ({ orders, onPayOrder }) => {
  if (orders.length === 0) {
    return <Typography variant="body2">Você não possui pedidos pendentes no momento.</Typography>;
  }

  return (
    <>
      {orders.map((order) => (
        <Paper elevation={2} sx={{ p: 2, mt: 2, mb: 3 }} key={order.id}>
          <Typography variant="h6" color="primary">
            {order.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {order.date} - {order.location}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Criado em: {order.createdAt}
          </Typography>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<Receipt />}
                onClick={() => onPayOrder(order.id)}
              >
                Finalizar Pagamento
              </Button>
            </Grid>
          </Grid>
        </Paper>
      ))}
    </>
  );
};

export default PendingOrders;
