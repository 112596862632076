import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Typography, Paper, Button } from "@mui/material";
import EventBatches from "../../components/EventBatches";

const EventBatchesPage = () => {
  const { id } = useParams(); // Obtém o ID do evento da URL
  const navigate = useNavigate(); // Hook para navegação programática

  const handleBackClick = () => {
    navigate("/producer/events"); // Navega para a rota desejada
  };

  return (
    <Box sx={{ maxWidth: 1200, margin: "0 auto", padding: 3 }}>
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleBackClick}
          sx={{ marginBottom: 2 }}
        >
          Voltar
        </Button>
        <Typography variant="h5" gutterBottom>
          Lotes de Ingressos
        </Typography>
        {/* Aqui chamamos o componente da mesma forma que no EventEdit */}
        <EventBatches eventId={id} />
      </Paper>
    </Box>
  );
};

export default EventBatchesPage;
